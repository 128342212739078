<template>
  <div class="userInfo">
    <h4>{{ $t("people.basic") }}</h4>
    <el-form
      ref="userForm"
      label-position="right"
      :rules="$i18n.locale === 'zh' ? rules : rules1"
      label-width="80px"
      :model="userData"
    >
      <el-row>
        <el-col :span="12">
          <el-form-item :label="$t('people.company')" prop="company">
            <el-input v-model="userData.company" class="userInfo-input" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item :label="$t('people.city')" prop="city">
            <el-input v-model="userData.city" class="userInfo-input" />
            <!-- <el-cascader
              class="userInfo-input userInfo-select"
              v-model="userData.city"
              :options="cityList"
              :props="cascaderProps"
              placeholder="请选择您所在城市"
            ></el-cascader>-->
            <!-- <el-select
              v-model="userData.city"
              class="userInfo-input userInfo-select"
              placeholder="请选择活动区域"
            >
              <el-option
                v-for="(item, index) in cityList"
                :key="index"
                :label="item.name"
                :value="item.name"
              />
            </el-select>-->
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item :label="$t('people.industry')" prop="industry">
            <el-input v-model="userData.industry" class="userInfo-input" />
            <!-- <el-select
              v-model="userData.industry"
              class="userInfo-input userInfo-select"
              placeholder="请选择您所属行业"
            >
              <el-option label="区域一" value="shanghai" />
              <el-option label="区域二" value="beijing" />
            </el-select>-->
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item :label="$t('people.profession')" prop="career">
            <el-input v-model="userData.career" class="userInfo-input" />
            <!-- <el-select
              v-model="userData.occupation"
              class="userInfo-input userInfo-select"
              placeholder="请选择您所属职业"
            >
              <el-option label="区域一" value="shanghai" />
              <el-option label="区域二" value="beijing" />
            </el-select>-->
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item :label="$t('people.phone')">
            <el-input
              class="userInfo-input userInfo-phone"
              v-model="userData.phone"
              readonly
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item :label="$t('people.mail')" prop="email">
            <el-input v-model="userData.email" class="userInfo-input" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item :label="$t('people.wechat')" prop="wechat">
            <el-input v-model="userData.wechat" class="userInfo-input" />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-button round @click="editUserInfo" v-loading="loading">{{
      $t("people.save")
    }}</el-button>
  </div>
</template>
<script>
import { userInfo, editUser } from "@/api/user";
import { validMailbox, validWechat } from "@/utils/validate";
import { toggle, toggleMessage } from "@/utils/common";
export default {
  // 我的信息
  name: "UserInfo",
  components: {},
  data() {
    //邮箱格式
    const validateMailbox = (rule, value, callback) => {
      if (!validMailbox(value)) {
        if (this.$i18n.locale === "zh") {
          callback(new Error("邮箱格式不正确"));
        } else {
          callback(new Error("Incorrect E-mail format"));
        }
      } else {
        callback();
      }
    };
    //微信格式
    const validateWechat = (rule, value, callback) => {
      if (!validWechat(value)) {
        if (this.$i18n.locale === "zh") {
          callback(new Error("微信号格式不正确"));
        } else {
          callback(new Error("Incorrect WeChat account format"));
        }
      } else {
        callback();
      }
    };
    return {
      loading: false,
      userData: {
        company: "",
        city: "",
        industry: "",
        career: "",
        phone: "",
        email: "",
        wechat: "",
      },
      userDataCopy: {},
      rules: {
        company: [
          { required: true, message: "请输入公司名称", trigger: "blur" },
        ],
        city: [{ required: true, message: "请输入所在城市", trigger: "blur" }],
        industry: [
          {
            required: true,
            message: "请输入您的行业",
            trigger: "blur",
          },
        ],
        career: [
          {
            required: true,
            message: "请输入您的职业",
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            message: "请输入您的邮箱",
            trigger: "blur",
          },
          {
            validator: validateMailbox,
            trigger: "blur",
          },
        ],
        wechat: [
          { required: true, message: "请输入您的微信", trigger: "blur" },
          {
            validator: validateWechat,
            trigger: "blur",
          },
        ],
      },
      rules1: {
        company: [
          {
            required: true,
            message: "Please fill in the company name",
            trigger: "blur",
          },
        ],
        city: [
          {
            required: true,
            message: "Please fill in your city",
            trigger: "blur",
          },
        ],
        industry: [
          {
            required: true,
            message: "Please fill in your industry",
            trigger: "blur",
          },
        ],
        career: [
          {
            required: true,
            message: "Please fill in your occupation",
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            message: "Please fill in your email address",
            trigger: "blur",
          },
          {
            validator: validateMailbox,
            trigger: "blur",
          },
        ],
        wechat: [
          {
            required: true,
            message: "Please fill in your wechat account",
            trigger: "blur",
          },
          {
            validator: validateWechat,
            trigger: "blur",
          },
        ],
      },
      cityList: [],
      // cascaderProps:{
      //   value: 'id',
      //   label: 'name',
      //   // children:'city'
      // }
    };
  },
  deactivated() {
    this.$refs.userForm.clearValidate();
    this.userData = JSON.parse(JSON.stringify(this.userDataCopy));
  },
  created() {
    this.getUser();
  },
  methods: {
    //修改用户信息
    editUserInfo() {
      this.loading = true;
      this.$refs.userForm
        .validate()
        .then(async () => {
          await editUser(this.userData);
          toggle("保存信息成功", "Information saved");
          this.userDataCopy = JSON.parse(JSON.stringify(this.userData));
          this.loading = false;
        })
        .catch((err) => {
          toggleMessage("将资料填写完整", "Fill in the information completely");
          this.loading = false;
          return Promise.resolve(err);
        });
    },
    //获取用户信息
    async getUser() {
      const { data } = await userInfo();
      this.userDataCopy = JSON.parse(JSON.stringify(data));
      this.userData = data;
    },
  },
};
</script>
<style lang="scss" scoped>
.userInfo {
  position: relative;
  color: #666666;
  h4 {
    width: 100%;
    text-align: center;
    margin-bottom: 50px;
  }

  .el-button {
    position: absolute;
    left: 50%;
    top: 400px;
    transform: translateX(-50%);
    width: 166px;
    height: 51px;
    border-radius: 25.5px;
    background: #ffc322;
    color: #fff;
    font-size: 18px;
    border: none;
  }
}
</style>
<style lang="scss">
.userInfo {
  .userInfo-input {
    .el-input__inner {
      border-radius: 0;
      border: none;
      border-bottom: 1px solid #ccc;
      outline: none;
    }
  }
  .userInfo-phone {
    .el-input__inner {
      border-radius: 0;
      border: none;
      width: 30%;
      background-color: transparent;
      color: #000;
      font-size: 16px;
      outline: none;
    }
  }
  .userInfo-select {
    width: 100%;
  }
}
</style>
